<template>
  <van-nav-bar title="修改密码" left-arrow @click-left="onClickLeft"></van-nav-bar>
  <div class="resetpwd" style="margin-top: 50px">
      <van-cell-group inset>
        <van-field v-model="user_no" label="账号" readonly />
        <van-field
          v-model="pwd"
          label="原密码"
          type="password"
          placeholder="请输入原密码"
          clearable
          maxlength="18"
          :rules="[{ required: true }]"
        />

        <van-field
          v-model="new_pwd"
          label="新密码"
          type="password"
          placeholder="请输入6-18位新密码"
          clearable
          maxlength="18"
          :rules="[{ required: true }]"
        />
        <van-field
          v-model="re_new_pwd"
          label="密码确认"
          type="password"
          placeholder="请再次输入要设置的新密码"
          clearable
          maxlength="18"
          :rules="[{ required: true }]"
        />
      </van-cell-group>
      
      <div style="margin: 60px 18px">
        <van-button round block type="primary" @click="onSubmit">
          修改密码
        </van-button>
      </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { Toast } from "vant";
import { useRouter } from "vue-router";
import { clearStorage, getStorage } from "../common/js/storeUtil";
import api from "../service/api";

export default {
  setup() {
    let user_no = ref("");
    let pwd = ref("");
    let new_pwd = ref("");
    let re_new_pwd = ref("");
    const router = useRouter();
    const onClickLeft = () => {
      router.go(-1);
    };
    
    onMounted(() => {
      user_no.value = getStorage("userNo");
    });

    function isPwd(pwd) {
      var regPwd = /^[a-zA-Z0-9~!@#$%^&*?]{6,18}$/;
      if (regPwd.test(pwd)) {
        return true;
      } else {
        return false;
      }
    }
    
    const onSubmit = () => {
      
      if (!isPwd(pwd.value)) {
        Toast("原密码格式错误");
        return;
      }
      if (!isPwd(new_pwd.value)) {
        Toast("新密码格式错误,应为6-18位数字字母或其组合");
        return;
      }
      if (!isPwd(re_new_pwd.value)) {
        Toast("确认密码格式错位,应为6-18位数字字母或其组合");
        return;
      }
      if (new_pwd.value != re_new_pwd.value) {
        Toast("两次输入的密码不一致");
        return;
      }
      
      api
        .resetPwd({
          user_no: user_no.value,
          oldPassword: pwd.value,
          newPassword: re_new_pwd.value,
        })
        .then((res) => {
          if (res.data.code == 1001) {
            Toast("密码修改成功,请重新登录");
            clearStorage();
            router.replace({
              path: "/",
            });
          } else {
            Toast(res.data.message);
          }
        })
        .catch((err) => {
          Toast(err.message);
        });
    };
    
    return {
      user_no,
      pwd,
      new_pwd,
      re_new_pwd,
      onSubmit,
      onClickLeft,
      onSubmit,
    };
    // const router = useRouter();
  },
};
</script>
